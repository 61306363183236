export const getTableColorIndicatorV2 = (config: any[], conveyorStepId: string, indicatorName: string, value: number) => {
  const conveyorConfig = config.find((config: any) => config.conveyorStepId === conveyorStepId);
  if (!conveyorConfig) {
    // eslint-disable-next-line no-console
    // console.error(`Conveyor ${conveyorStepId} not found in config`);
    return null;
  }
  if (!conveyorConfig.rules) {
    // eslint-disable-next-line no-console
    console.error(`Rules not found in conveyor ${conveyorStepId}`);
    return null;
  }
  if (!conveyorConfig.rules.indicators) {
    // eslint-disable-next-line no-console
    console.error(`Indicators not found in conveyor ${conveyorStepId}`);
    return null;
  }
  const thresholds = conveyorConfig.rules.indicators[indicatorName];
  const threshold = thresholds.find((threshold) => {
    if (value === null) {
      return threshold.min === null && threshold.max === null;
    }
    if (threshold.min === undefined && threshold.max) {
      return value <= threshold.max;
    }
    if (threshold.max === undefined && threshold.min) {
      return value >= threshold.min;
    }
    if (threshold.min !== null && threshold.max !== null) {
      return value >= threshold.min && value <= threshold.max;
    }
    return false;
  });

  return threshold || null;
};
export default {
  getTableColorIndicatorV2,
};
