import PropTypes from 'prop-types';
import { Tooltip, tooltipClasses, styled } from '@mui/material';
import Qr from '../Qr';

//--------------------------------------------------------------------------------------------

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      width: 106,
      height: 104,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
  })
);

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export default function QRTooltip({ qr, label }) {
  return (
    <LightTooltip
      title={<Qr size={'90px'} qrId={qr} bgColor={'rgba(0,0,0,0)'} level={'H'} />}
      arrow
      style={{ cursor: 'pointer' }}
    >
      <StyledSpan>{label || qr}</StyledSpan>
    </LightTooltip>
  );
}

QRTooltip.propTypes = {
  qr: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
