import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import Iconify from './Iconify';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 35,
  margin: 2,
  lineHeight: 1,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default function ConveyorsMenu({ conveyorsMenuList }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);

  const activeSubStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleSetSelectedSubmenu = (submenu) => {
    setSelectedSubmenu((prev) => (prev === submenu ? null : submenu));
  };

  const handleMenuClick = (id) => {
    navigate(`/conveyor-transactions/${id}`);
  };

  const findSubmenuById = useCallback((list, targetId) =>
    list.reduce((acc, item) => {
      if (acc) return acc;
  
      if (item.id === targetId) {
        return { foundIn: list, matchedItem: item };
      }
  
      if (item.submenu) {
        return findSubmenuById(item.submenu, targetId);
      }
  
      return null;
    }, null),
    []
  );

  useEffect(() => {
    const pathId = location.pathname.split('/').pop();

    const matchedSubmenu = findSubmenuById(conveyorsMenuList, pathId);

    if (matchedSubmenu) {
      setOpen(true);
      setSelectedSubmenu(matchedSubmenu.foundIn);
    }
  }, [location.pathname, conveyorsMenuList, findSubmenuById]);

  return (
    <Box>
      {conveyorsMenuList.map(
        (
          menu // Loop through conveyorsMenuList
        ) => (
          <div key={menu.title}>
            <ListItemStyle onClick={handleOpen}>
              <ListItemIconStyle>
                <Iconify icon="material-symbols:conveyor-belt-outline" sx={{ width: 22, height: 22 }} />
              </ListItemIconStyle>
              <ListItemText disableTypography primary={menu.title} />
              <Iconify
                icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </ListItemStyle>
            <Collapse in={open || (selectedSubmenu && menu.submenu === selectedSubmenu)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ ml: 4 }}>
                {menu.submenu.map((item) => (
                  <Box key={item.title}>
                    <ListItemStyle onClick={() => item.submenu && handleSetSelectedSubmenu(item.submenu)}>
                      <ListItemIconStyle>
                        <Iconify icon="material-symbols:conveyor-belt-outline" sx={{ width: 22, height: 22 }} />
                      </ListItemIconStyle>
                      <ListItemText
                        disableTypography
                        primary={item.title === 'undefined' ? t('label.ungrouped') : item.title}
                      />
                      <Iconify
                        icon={
                          selectedSubmenu === item.submenu
                            ? 'eva:arrow-ios-downward-fill'
                            : 'eva:arrow-ios-forward-fill'
                        }
                        sx={{ width: 16, height: 16, ml: 1 }}
                      />
                    </ListItemStyle>
                    <Collapse in={selectedSubmenu === item.submenu} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ ml: 4 }}>
                        {selectedSubmenu?.map((item) => {
                          const isActiveSub = matchPath(
                            {
                              path: `/conveyor-transactions/${item.id}`,
                              end: true,
                            },
                            location.pathname
                          );

                          return (
                            <ListItemStyle
                              sx={{
                                ...(isActiveSub && activeSubStyle),
                              }}
                              key={item.title}
                              onClick={() => handleMenuClick(item.id)}
                            >
                              <ListItemIconStyle>
                                <Iconify
                                  icon={item.icon ? item.icon : 'material-symbols:conveyor-belt-outline'}
                                  sx={{ width: 22, height: 22 }}
                                />
                              </ListItemIconStyle>
                              <ListItemText disableTypography primary={item.title} />
                            </ListItemStyle>
                          );
                        })}
                      </List>
                    </Collapse>
                  </Box>
                ))}
              </List>
            </Collapse>
          </div>
        )
      )}
    </Box>
  );
}

ConveyorsMenu.propTypes = {
  conveyorsMenuList: PropTypes.array,
};
