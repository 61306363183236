import { TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import QRTooltip from '../../../components/tooltips/QRTooltip';
import { TableCell } from '../table';
import ConveyorTransactionsSubitems from './ConveyorTransactionsSubitems';

const SIZE = 'xs';

export default function ConveyorTransactionsRow({ row, loading, seeCost }) {
  const qrId = row?.transactions[0]?.qrId;
  const tobacco = row?.transactions[0]?.tobacco?.code;

  return (
    <>
      <TableRow size={SIZE} hover key={row.tobaccoContainer} tabIndex={-1}>
        <TableCell
          align="left"
          sx={{ maxWidth: { xs: '50px', sm: '200px', md: 'auto' }, borderBottom: '2px solid black' }}
        >
          {tobacco}
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: '2px solid black' }}>
          <QRTooltip qr={qrId} />
        </TableCell>
        <TableCell sx={{ borderBottom: '2px solid black' }}>
          <ConveyorTransactionsSubitems list={row?.transactions} loading={loading} seeCost={seeCost} />
        </TableCell>
      </TableRow>
    </>
  );
}

ConveyorTransactionsRow.propTypes = {
  row: PropTypes.object,
  loading: PropTypes.bool,
  seeCost: PropTypes.bool,
};
