import { useEffect, useState } from 'react';

// ------------------------------------------------------

// const useKeyPress = (targetKey) => {
//   // State for keeping track of whether key is pressed
//   const [keyPressed, setKeyPressed] = useState(false);
//   // If pressed key is our target key then set to true
//   function downHandler({ key }) {
//     if (key === targetKey) {
//       setKeyPressed(true);
//     }
//   }
//   // If released key is our target key then set to false
//   const upHandler = ({ key }) => {
//     if (key === targetKey) {
//       setKeyPressed(false);
//     }
//   };
//   // Add event listeners
//   useEffect(() => {
//     window.addEventListener('keydown', downHandler);
//     window.addEventListener('keyup', upHandler);
//     // Remove event listeners on cleanup
//     return () => {
//       window.removeEventListener('keydown', downHandler);
//       window.removeEventListener('keyup', upHandler);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   return keyPressed;
// };

// const useKeyPress = (callback, keyCodes) => {
//   const handler = ({ code }) => {
//     if (keyCodes.includes(code)) {
//       callback();
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('keydown', handler);
//     return () => {
//       window.removeEventListener('keydown', handler);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [keyCodes]);
// };

const useKeyPress = (callback, keyCodes) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const keyDownHandler = ({ code }) => {
    if (keyCodes.includes(code)) {
      setKeyPressed(true);
    }
  };

  const keyUpHandler = ({ code }) => {
    if (keyCodes.includes(code)) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    if (keyPressed) callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyPressed]);

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', keyUpHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyCodes]);
};

export default useKeyPress;
