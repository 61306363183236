export const GetConveyorsMenuReducer = {
    pending: (state) => ({
      ...state,
      loading: 'pending',
    }),
    fulfilled: (state, action) => {
      const { data } = action.payload;
  
      return {
        ...state,
        list: data,
        loading: 'succeeded',
      };
    },
    rejected: (state) => ({
      ...state,
      loading: 'failed',
    }),
  };