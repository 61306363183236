import { createAsyncThunk } from '@reduxjs/toolkit';
import conveyorsApi from '../../../api/conveyorsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'conveyorsMenu';

export const GetConveyorsMenu = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorsMenu`,
  async (_, thunkAPI) => {
    const res = await conveyorsApi.getMenu();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);