import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import { DaysOfTheWeek, Weekday } from '../constants/enums';
import { useFactoryConfigValue } from '../providers/FactoryOptionsProvider';
import selectors from '../redux/reducers/conveyorTransactions/selectors';
import { GetConfigs, GetConveyorTransactions } from '../redux/reducers/conveyorTransactions/thunks';
import conveyorsMenu from '../redux/reducers/conveyorsMenu/selectors';
import ConveyorTransactionsTable from '../sections/@dashboard/conveyorTransactions/ConveyorTransactionsTable';
import TobaccoProductionDefualtFilters from '../sections/@dashboard/tobaccoProductions/TobaccoProductionDefualtFilters';

// --------------------------------------------------------------------------

const PAGE = 'conveyorTransactions';

export default function ConveyorTransactionsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLanguage = useTranslation().i18n.language;

  const params = useParams();
  const { id } = params;
  const TRANSACTION_PAGE = `${PAGE}_${id}`;

  // state
  const loading = useSelector(selectors.isLoading);
  const list = useSelector(selectors.list);
  const conveyorsMenuList = useSelector(conveyorsMenu.list);
  const findSubmenuById = (list, targetId) => {
    const flattenList = (items) =>
      items.reduce((acc, item) => {
        acc.push(item);
        if (item.submenu) {
          acc.push(...flattenList(item.submenu));
        }
        return acc;
      }, []);

    const flatList = flattenList(list);
    const foundItem = flatList.find((item) => item.id === targetId);

    return foundItem || null;
  };
  const menu = findSubmenuById(conveyorsMenuList, id);

  const header = menu && currentLanguage === 'en' ? menu?.title : menu?.titleEs;

  // week start
  const AttendanceFirstWeekDay = useFactoryConfigValue('AttendanceFirstWeekDay');
  const weekStartsOn = Weekday[AttendanceFirstWeekDay] || Weekday[DaysOfTheWeek.Monday];

  const onSubmit = useCallback(
    (data) => {
      const payload = {
        ...data,
        id,
      };

      dispatch(GetConveyorTransactions(payload));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(GetConfigs(id));
  }, [dispatch, id]);

  return (
    <Page title={t(`pages.${PAGE}`)}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <Typography variant="h4">{header}</Typography>
      </Stack>
      <TobaccoProductionDefualtFilters onSubmit={onSubmit} weekStartsOn={weekStartsOn} page={TRANSACTION_PAGE} />
      <ConveyorTransactionsTable list={list} loading={loading} />
    </Page>
  );
}
