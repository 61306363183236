import { Box, Checkbox, TableHead as MuiTableHead, Skeleton, TableRow } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { getCsvData } from '../../../services/csv';
import TableCell from './TableCell';
import TableHeadCell from './TableHeadCell';
import TableMoreMenu from './TableMoreMenu';
import TableMoreMenuItem from './TableMoreMenu/TableMoreMenuItem';

// ----------------------------------------------------------------------
const TableHead = ({
  isLoading,
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected = 0,
  onRequestSort,
  onSelectAllClick,
  size = 'md',
  tableData,
}) => {
  const { t } = useTranslation();

  const csvLink = useRef();

  const handleDownloadCSV = () => csvLink.current.link.click();

  const createSortHandler = (property) => (event) => {
    onRequestSort?.(event, property);
  };

  // in header we have some technical columns like collapse-button, actions, etc.
  const technicalColumns = ['collapse-button', 'actions'];
  const tableHeaders = tableData?.headers.filter((header) => {
    if (technicalColumns.includes(header.id)) {
      return false;
    }
    return header;
  });

  const tableToCsv = tableData
    ? getCsvData({
        data: tableData?.data,
        tableHead: tableHeaders,
        filename: tableData?.filename
          ? `${tableData?.filename}_${format(new Date(), 'dd-MM-yyyy')}`
          : format(new Date(), 'dd-MM-yyyy'),
        canDownloadIfEmptyData: true,
      })
    : null;

  const SelectAllCheckbox = (
    <Checkbox
      indeterminate={numSelected > 0 && numSelected < rowCount}
      checked={rowCount > 0 && numSelected === rowCount}
      onChange={onSelectAllClick}
    />
  );

  return (
    <MuiTableHead>
      <TableRow>
        {onSelectAllClick && (
          <TableCell size={size} padding="checkbox">
            <Box display="flex" alignItems="center">
              {isLoading ? <Skeleton width={25}>{SelectAllCheckbox}</Skeleton> : SelectAllCheckbox}
              {!isLoading && numSelected > 0 && <Box>{numSelected}</Box>}
            </Box>
          </TableCell>
        )}
        {headLabel.map((headCell) =>
          headCell.id === 'actions' && tableData ? (
            <TableCell key={headCell.id} align="right">
              <TableMoreMenu>
                <TableMoreMenuItem onClick={handleDownloadCSV} icon="ph:file-csv">
                  {t('button.exportAllData')}
                  <CSVLink
                    data={tableToCsv.data}
                    headers={tableToCsv.headers}
                    filename={tableToCsv.filename}
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                </TableMoreMenuItem>
              </TableMoreMenu>
            </TableCell>
          ) : (
            <TableHeadCell
              key={headCell.id}
              size={size}
              data={headCell}
              orderBy={orderBy}
              order={order}
              createSortHandler={createSortHandler}
              isLoading={isLoading}
            />
          )
        )}
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.propTypes = {
  isLoading: PropTypes.bool,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  tableData: PropTypes.object,
};

export default TableHead;
