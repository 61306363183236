// redux store
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import AlertReducer from './reducers/AlertReducer';
import SettingsReducer from './reducers/SettingsReducer';

import UserNotificationsReducer from './reducers/userNotifications';

import SessionReducer from './reducers/SessionReducer';

import DashboardReducer from './reducers/dashboard';

import FactoryReducer from './reducers/FactoryReducer';

import AuthenticationActivityReducer from './reducers/authenticationActivity';

import AttendanceReducer from './reducers/AttendanceReducer';
import CigarPerformanceReducer from './reducers/cigarPerformance';
import CigarRollPricesReducer from './reducers/cigarRollPrices';
import CigarUnitProductionReducer from './reducers/cigarUnitProduction';
import ConveyorCigarUnitProductionsReducer from './reducers/ConveyorCigarUnitProductionsReducer';
import EmployeesReducer from './reducers/EmployeesReducer';
import EmployeeTeamsReducer from './reducers/employeeTeams';
import EmployeeUnitProductionsReducer from './reducers/EmployeeUnitProductionsReducer';
import ExpensesReducer from './reducers/ExpensesReducer';
import PayrollReducer from './reducers/PayrollReducer';
import TobaccoDryRoomProductionReducer from './reducers/tobaccoDryProduction';
import TobaccoGaleraProductionReducer from './reducers/tobaccoGaleraProduction';
import TobaccoHumidityRoomProductionReducer from './reducers/tobaccoHumidificationProduction';
import TobaccoProductionCorrectionReducer from './reducers/tobaccoProductionCorrection';
import TobaccoProductionReducer from './reducers/tobaccoProduction';
import TobaccoTransactionsReducer from './reducers/tobaccoTransactions';
import TobaccoTransferSummaryReducer from './reducers/tobaccoTransferSummary';
import UsersReducer from './reducers/users';

import CigarsReducer from './reducers/cigar';
import cigarRecipeForTooltipReducer from './reducers/cigarRecipeForTooltip';
import CigarUnitBoxReducer from './reducers/cigarUnitBoxProduction';
import CigarUnitReducer from './reducers/CigarUnitReducer';

import boxPressedProcessReducer from './reducers/boxPressedProcess';
import ConveyorStepRecordsReducer from './reducers/conveyorStepRecords';
import ConveyorTemplateReducer from './reducers/conveyorTemplate';
import CycleCounts from './reducers/cycleCount';
import DepartmentRequestReducer from './reducers/departmentRequest';
import DepartmentReducer from './reducers/department';
import DistributorsReducer from './reducers/distributors';
import FactoryOptionsReducer from './reducers/factoryOptions';
import FinishedGoodInventoryReducer from './reducers/finishedGoodInventory';
import FinishedGoodTemplate from './reducers/finishedGoodTemplate';
import PackingListReducer from './reducers/packingList';
import PaymentCodeCigarsMappingReducer from './reducers/paymentCodeCigarMapping';
import PurchaseOrderReducer from './reducers/purchaseOrder';
import QrCodesReducer from './reducers/QrCodesReducer';
import qrPdfTemplatesReducer from './reducers/qrPdfTemplates';
import RawMaterialInventoryReducer from './reducers/rawMaterialInventory';
import RoomsReducer from './reducers/rooms';
import SuppliersReducer from './reducers/suppliers';
import TobaccoContainers from './reducers/tobaccoContainers';
import tobaccoCostReducer from './reducers/tobaccoCost';
import TobaccoInventoryReducer from './reducers/tobaccoInventory';
import tobaccoMappingsReducer from './reducers/tobaccoMappings';
import TobaccosReducer from './reducers/tobaccos';
import TobaccoSalesReducer from './reducers/tobaccoSales';
import userWhiteListReducer from './reducers/userWhiteList';
import WorkInProgressInventoryReducer from './reducers/workInProgressInventory';
import WorkOrdersReducer from './reducers/workOrders';
import pageIndicatorsReducer from './reducers/pageIndicators';
import tobaccoCorrectionsReducer from './reducers/tobaccoCorrections';
import schedulerTasksReducer from './reducers/schedulerTasks';
import conveyorsMenuReducer from './reducers/conveyorsMenu';
import conveyorTransactionsReducer from './reducers/conveyorTransactions';

// Middlewares
// import persistMiddleware, { reHydrateStore } from './middlewares/persistMiddleware';

// ------------------------------------------------------

export const store = configureStore({
  reducer: {
    alert: AlertReducer,
    settings: SettingsReducer,

    userNotifications: UserNotificationsReducer,

    session: SessionReducer,

    dashboard: DashboardReducer,

    factory: FactoryReducer,
    authenticationActivity: AuthenticationActivityReducer,
    users: UsersReducer,
    employees: EmployeesReducer,
    employeeTeams: EmployeeTeamsReducer,
    attendance: AttendanceReducer,
    cigarUnitProduction: CigarUnitProductionReducer,
    cigarPerformance: CigarPerformanceReducer,
    tobaccoProduction: TobaccoProductionReducer,
    tobaccoDryProduction: TobaccoDryRoomProductionReducer,
    tobaccoHumidificationProduction: TobaccoHumidityRoomProductionReducer,
    tobaccoProductionCorrection: TobaccoProductionCorrectionReducer,
    tobaccoTransferSummary: TobaccoTransferSummaryReducer,
    tobaccoGaleraProduction: TobaccoGaleraProductionReducer,
    tobaccoTransactions: TobaccoTransactionsReducer,
    conveyorCigarUnitProductions: ConveyorCigarUnitProductionsReducer,
    employeeUnitProductions: EmployeeUnitProductionsReducer,
    payroll: PayrollReducer,
    expenses: ExpensesReducer,
    cigars: CigarsReducer,
    cigarRecipeForTooltip: cigarRecipeForTooltipReducer,
    cigarRollPrices: CigarRollPricesReducer,
    cigarUnit: CigarUnitReducer,
    cigarUnitBox: CigarUnitBoxReducer,

    paymentCodeCigarsMapping: PaymentCodeCigarsMappingReducer,

    inventory: WorkInProgressInventoryReducer,
    finishedGoodInventory: FinishedGoodInventoryReducer,
    rawMaterialInventory: RawMaterialInventoryReducer,
    packingList: PackingListReducer,
    departmentRequest: DepartmentRequestReducer,
    departments: DepartmentReducer,
    workOrders: WorkOrdersReducer,
    suppliers: SuppliersReducer,
    purchaseOrder: PurchaseOrderReducer,

    qrCodes: QrCodesReducer,
    rooms: RoomsReducer,

    distributors: DistributorsReducer,

    factoryOptions: FactoryOptionsReducer,
    qrPdfTemplates: qrPdfTemplatesReducer,

    tobaccos: TobaccosReducer,
    tobaccoInventory: TobaccoInventoryReducer,
    tobaccoContainers: TobaccoContainers,
    tobaccoCorrections: tobaccoCorrectionsReducer,

    conveyorStepRecords: ConveyorStepRecordsReducer,
    conveyorTemplates: ConveyorTemplateReducer,
    tobaccoSales: TobaccoSalesReducer,
    cycleCounts: CycleCounts,
    finishedGoodTemplate: FinishedGoodTemplate,
    boxPressedProcess: boxPressedProcessReducer,
    tobaccoCost: tobaccoCostReducer,
    userWhiteList: userWhiteListReducer,
    tobaccoMappings: tobaccoMappingsReducer,
    pageIndicators: pageIndicatorsReducer,
    schedulerTasks: schedulerTasksReducer,
    conveyorsMenu: conveyorsMenuReducer,
    conveyorTransactions: conveyorTransactionsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  // preloadedState: reHydrateStore(),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  // 	immutableCheck: { ignoredPaths: ['formPages'] },
  // 	serializableCheck: { ignoredPaths: ['formPages'] }
  // }).concat(persistMiddleware)
});
