import { Box, Table, TableBody, TableRow, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConditionalTooltip from '../../../components/tooltips/ConditionalTooltip';
import { dateLocales } from '../../../locales/i18n';
import selectors from '../../../redux/reducers/conveyorTransactions/selectors';
import { getTimeDifferenceWithDefaultEnd, getTimeDifferenceWithDefaultEndFormatted } from '../../../utils/formatTime';
import { getTableColorIndicatorV2 } from '../../../utils/tableColorIndicatorV2.ts';
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { TableCell, TableHead } from '../table';

const SIZE = 'xs';

export default function ConveyorTransactionsSubitems({ list, loading, seeCost }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = useTranslation().i18n.language;
  const lang = i18n.language || window.localStorage.i18nextLng || 'en';
  const locale = dateLocales[lang];
  const configs = useSelector(selectors.configs);
  const pageConfig = configs
    .filter((config) => config.rules)
    .map((config) => ({
      conveyorStepId: config._id,
      rules: config.rules,
    }));

  const order = 'asc';
  const orderBy = 'createdAt';

  const getTableHead = (t) => [
    { id: 'conveyorStep.name', label: t('table.conveyorStep'), alignRight: false },
    { id: 'type', label: t('table.type'), alignRight: false },
    { id: 'quantity', label: t('table.quantity'), alignRight: true },
    { id: 'difference', label: t('table.difference'), alignRight: true },
    { id: 'differenceInPercent', label: t('table.differenceInPercent'), alignRight: true },
    ...(seeCost
      ? [
          { id: 'costPerUnit', label: t('label.costPerUnit'), alignRight: true },
          { id: 'costPerUnitDifferenceInPercent', label: t('table.costPerUnitDifferenceInPercent'), alignRight: true },
        ]
      : []),
    { id: 'room.name', label: t('table.factoryDepartment'), alignRight: false },
    { id: 'operationDuration', label: t('table.operationDuration'), alignRight: false },
    { id: 'createdBy', label: t('table.createdBy'), alignRight: false },
    { id: 'updatedAt', label: t('table.updatedAt'), alignRight: false },
    { id: 'createdAt', label: t('table.createdAt'), alignRight: false },
  ];

  // Upgrade list with calculated fields
  const upgradedList = list.map((item, index) => {
    const currentQuantity = item.quantity || 0;
    const previousQuantity = index > 0 ? list[index - 1].quantity || 0 : 0;

    const difference = index !== 0 ? currentQuantity - previousQuantity : null;

    const differenceInPercent = (() => {
      if (index === 0) return null;
      if (previousQuantity === 0) return null;
      return ((difference / previousQuantity) * 100).toFixed(2);
    })();

    const calculateOperationDuration = (previousDate, currentDate) => {
      const duration = getTimeDifferenceWithDefaultEnd(previousDate, currentDate);
      const durationTotalMinutes = duration?.minutes + duration?.hours * 60 + duration?.days * 24 * 60;
      const durationTotalSeconds = duration?.seconds + durationTotalMinutes * 60;

      return durationTotalSeconds;
    };

    const operationDuration = (() => {
      if (index === 0) return 0;
      const previousDate = list[index - 1].createdAt;
      const currentDate = item.createdAt;
      return calculateOperationDuration(previousDate, currentDate);
    })();

    const durationFormatted = (() => {
      if (!operationDuration) return null;
      const previousDate = list[index - 1].createdAt;
      const currentDate = item.createdAt;
      return getTimeDifferenceWithDefaultEndFormatted(previousDate, currentDate, locale);
    })();

    const costPerUnitDifferenceInPercent = (() => {
      if (index === 0) return null;
      const previousCostPerUnit = list[index - 1].costPerUnit;
      if (previousCostPerUnit === 0) return null;
      return ((item.costPerUnit - previousCostPerUnit) / previousCostPerUnit) * 100;
    })();

    return {
      ...item,

      createdBy: item.createdBy?.fullName,
      updatedAt: t('date.shortWithTime', { date: parseISO(item.updatedAt) }),
      createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
      currentQuantity,
      previousQuantity,
      difference,
      differenceInPercent,
      operationDuration,
      durationFormatted,
      costPerUnitDifferenceInPercent,
    };
  });

  // temparary config for page
  // id = '64c982962b8dbd636642cf9f' is for Receive Tobacco Bale step
  // eslint-disable-next-line no-unused-vars
  // const pageConfig = [
  //   {
  //     conveyorStepId: tobaccoContainerId,
  //     rules: {
  //       indicators: {
  //         differencePercent: [
  //           {
  //             min: Math.min(...upgradedList.map((obj) => parseFloat(obj.differenceInPercent))),
  //             max: Math.max(...upgradedList.map((obj) => parseFloat(obj.differenceInPercent))),
  //           },
  //           {
  //             color: '#FF0000',
  //             min: 9,
  //             max: 15,
  //             tooltip: `${t('tobaccoHumidityRoomProductionPage.tooltip', { min: 9, max: 15 })}`,
  //           },
  //           {
  //             color: '#FF0000',
  //             min: 75,
  //             tooltip: `${t('tobaccoHumidityRoomProductionPage.tooltip', { min: 20 })}`,
  //           },
  //         ],
  //         duration: [
  //           {
  //             min: Math.min(...upgradedList.map((obj) => obj.operationDuration)),
  //             max: Math.max(...upgradedList.map((obj) => obj.operationDuration)),
  //           },
  //           {
  //             color: '#FFFF00',
  //             min: 0,
  //             max: 10,
  //             tooltip: `${t('tobaccoHumidityRoomProductionPage.tooltip', {
  //               min: formatMinutes(0),
  //               max: formatMinutes(10),
  //             })}`,
  //           },
  //           {
  //             color: '#FF0000',
  //             min: 60 * 24 * 1,
  //             tooltip: `${t('tobaccoHumidityRoomProductionPage.tooltip', { min: formatMinutes(60 * 24 * 1) })}`,
  //           },
  //         ],
  //       },
  //     },
  //   },
  //   {
  //     conveyorStepId: '64994d1fdc36e6d3e8652ebf',
  //     rules: {
  //       required: {
  //         type: 'Recieved',
  //       },
  //     },
  //   },
  // ];

  const upgradedListWithColors = upgradedList.map((item) => {
    const indicatorForDifferencePercent = getTableColorIndicatorV2(
      pageConfig,
      item.conveyorStep._id,
      'differencePercent',
      Number(item.differenceInPercent)
    );

    const operationDurationInMinutes = item.operationDuration / 60;

    const indicatorForOperationDuration = getTableColorIndicatorV2(
      pageConfig,
      item.conveyorStep._id,
      'duration',
      operationDurationInMinutes
    );

    return {
      ...item,
      differenceInPercentIndicator: indicatorForDifferencePercent,
      operationDurationIndicator: indicatorForOperationDuration,
    };
  });

  const filteredItems = applySortFilter(upgradedListWithColors, getComparator(order, orderBy));

  return (
    <Table>
      <TableHead
        isLoading={loading}
        size={SIZE}
        order={order}
        orderBy={orderBy}
        headLabel={getTableHead(t)}
        rowCount={list.length}
      />
      <TableBody>
        {filteredItems.map((item) => (
          <TableRow size={SIZE} hover key={item._id} tabIndex={-1}>
            <TableCell align="left" width={'200px'}>
              {item.conveyorStep?.name}
            </TableCell>
            <TableCell align="left" width={'100px'}>
              {item.type}
            </TableCell>
            <TableCell align="right" width={'50px'}>
              {item.currentQuantity}
            </TableCell>
            <TableCell align="right" width={'20px'}>
              {item.difference}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: item.differenceInPercentIndicator?.color,
              }}
            >
              <ConditionalTooltip
                title={
                  currentLanguage === 'en'
                    ? item?.differenceInPercentIndicator?.tooltip
                    : item?.differenceInPercentIndicator?.tooltipEs
                }
              >
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  {item.differenceInPercent ? `${item.differenceInPercent} %` : 'N/A'}
                </Box>
              </ConditionalTooltip>
            </TableCell>
            {seeCost && (
              <TableCell align="right" width={'100px'}>
                {item.costPerUnit.toFixed(2)}
              </TableCell>
            )}
            {seeCost && (
              <TableCell size={SIZE} align="right" style={{ whiteSpace: 'nowrap' }}>
                {item.costPerUnitDifferenceInPercent ? (
                  <Typography
                    variant={'body3'}
                    color={item.costPerUnitDifferenceInPercent > 0 ? 'error.main' : 'success.main'}
                  >
                    ({item.costPerUnitDifferenceInPercent.toFixed(2)}%)
                  </Typography>
                ) : (
                  ''
                )}
              </TableCell>
            )}
            <TableCell align="left" width={'200px'}>
              {item.factoryDepartment?.name}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                backgroundColor: item.operationDurationIndicator?.color,
              }}
            >
              <ConditionalTooltip
                title={
                  currentLanguage === 'en'
                    ? item?.operationDurationIndicator?.tooltip
                    : item?.operationDurationIndicator?.tooltipEs
                }
              >
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  {item.durationFormatted}
                </Box>
              </ConditionalTooltip>
            </TableCell>
            <TableCell align="left">{item.createdBy}</TableCell>
            <TableCell align="left">{item.updatedAt}</TableCell>
            <TableCell align="left">{item.createdAt}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

ConveyorTransactionsSubitems.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  seeCost: PropTypes.bool,
};
