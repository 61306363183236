import { Card, Table, TableBody, TableContainer, TableFooter, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { ExtraSmallTableCell as TableCell, TableHead, TableToolbar } from '../table';
import ConveyorTransactionsRow from './ConveyorTransactionsRow';
import useAccess from '../../../hooks/useAccess';

const SIZE = 'xs';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'transactions[0]?.tobacco.code', label: t('label.tobacco'), alignRight: false },
  { id: 'transactions[0].qrId', label: t('table.qr'), alignRight: false },
  { id: 'table', alignRight: false },
];

// ----------------------------------------------------------------------

const getFilteredItem = (item) => ({
  tobacco: item.transactions[0]?.tobacco.code,
  flatCode: item.transactions[0]?.tobacco.code?.replace(/_/g, ''),
  qrId: item[0]?.qrId,
});

export default function ConveyorTransactionsTable({ list = [], loading }) {
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (value) => {
    setFilterName(value);
  };
  const filteredList = applySortFilter(list, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item)
  );
  const isItemNotFound = filteredList.length === 0;  

  const seeCost = useAccess('tobaccoInventory.views.cost'); 

  return (
    <Card>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} submitOnEnter />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              isLoading={loading}
              size={SIZE}
              order={order}
              orderBy={orderBy}
              headLabel={getTableHead(t)}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />

            {!loading && (
              <TableBody>
                {filteredList.map((row) => (
                  <ConveyorTransactionsRow
                    key={row.tobaccoContainer}
                    row={row}
                    loading={loading}
                    columnCount={getTableHead(t).length}
                    seeCost={seeCost}
                  />
                ))}
              </TableBody>
            )}

            {isItemNotFound || loading ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound isLoading={loading} searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableFooter />
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      {/* <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          {list.map((container) => (
            <Accordion key={container.tobaccoContainer}>
              <AccordionSummary
                expandIcon={<Iconify icon="material-symbols:conveyor-belt-outline" sx={{ width: 22, height: 22 }} />}
              >
                <Typography>{`Container: ${container.tobaccoContainer}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableHead
                    isLoading={loading}
                    size={SIZE}
                    order={order}
                    orderBy={orderBy}
                    headLabel={getTableHead(t)}
                    rowCount={container.transactions.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {container.transactions.map((transaction) => (
                      <TableRow size={SIZE} hover key={transaction._id} tabIndex={-1}>
                        <TableCell align="left">{transaction.tobacco.code}</TableCell>
                        <TableCell align="left">
                          <QRTooltip qr={transaction.qrId} />
                        </TableCell>
                        <TableCell align="right">{transaction.quantity}</TableCell>
                        <TableCell align="left">{transaction.type}</TableCell>
                        <TableCell align="left">{transaction.conveyorStep.name}</TableCell>
                        <TableCell align="left">{transaction.factoryDepartment.name}</TableCell>
                        <TableCell align="left">
                          {t('date.shortWithTime', { date: parseISO(transaction.createdAt) })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
        </TableContainer>
      </Scrollbar> */}
    </Card>
  );
}

ConveyorTransactionsTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
};
