import dayjs from 'dayjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import conveyorsApi from '../../../api/conveyorsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'conveyorTransactions';

export const GetConveyorTransactions = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorTransactions`,
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await conveyorsApi.getTransactions(params.id, params.startDate, params.endDate);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetConfigs = createAsyncThunk(
  `${REDUCER_NAME}/getConfigs`,
  async (id, thunkAPI) => {
    const res = await conveyorsApi.getConfigs(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);