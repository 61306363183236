import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const conveyorApi = {
  // GET
  getTemplates: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/templates`);

      return res;
    } catch (error) {
      return error.response;
    }
  },

  getSimplifiedTemplates: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/simplified-templates`);

      return res;
    } catch (error) {
      return error.response;
    }
  },

  createUnits: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/unitConveyors/createUnits`, data);

      return res;
    } catch (error) {
      return error.response;
    }
  },

  // PUT
  transferUnits: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/unitConveyors/transferUnit`, data);

      return res;
    } catch (error) {
      return error.response;
    }
  },

  convertUnit: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/unitConveyors/updateUnits`, data);

      return res;
    } catch (error) {
      return error.response;
    }
  },

  getMenu: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/v1/conveyors-menu`);

      return res;
    } catch (error) {
      return error.response;
    }
  },

  getTransactions: async (id, startDate, endDate) => {
    try {
      const params = {startDate, endDate};
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/v1/conveyor-transactions/${id}`, { params });

      return res;
    } catch (error) {
      return error.response;
    }
  },

  getConfigs: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/v1/conveyor-step/configs/${id}`);

      return res;
    } catch (error) {
      return error.response;
    }
  },
};

export default conveyorApi;
