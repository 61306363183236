import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';

import { useAuth } from '../providers/AuthProvider';

// -----------------------------------------------------------------------

const Qr = ({ size, qrId, bgColor, level }) => {
  const { account } = useAuth();
  const { factory } = account;
  const { _id: factoryId } = factory;
  return (
    <QRCodeSVG
      size={size}
      value={`${window.location.host}/${factoryId}/unit/${qrId}`}
      bgColor={bgColor}
      level={level}
    />
  );
};

Qr.propTypes = {
  size: PropTypes.string,
  qrId: PropTypes.string,
  bgColor: PropTypes.string,
  level: PropTypes.string,
};

export default Qr;
