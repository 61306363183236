import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { format, startOfWeek } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RHFDatePicker } from '..';
import useKeyPress from '../../../hooks/useKeyPress';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

const WeekPicker = ({ onChange, value, weekStartsOn, showPrevWeekLastDay = true }) => {
  const { t } = useTranslation();

  const handlePrevWeek = () => {
    const newWeek = new Date(value);
    newWeek.setDate(newWeek.getDate() - 7);

    onChange(newWeek);
  };

  const handleNextWeek = () => {
    const newWeek = new Date(value);
    newWeek.setDate(newWeek.getDate() + 7);

    onChange(newWeek);
  };

  const handleSetCurrentWeek = () => {
    let adjustedWeekStartsOn = weekStartsOn;
    if (showPrevWeekLastDay) {
      adjustedWeekStartsOn = weekStartsOn > 0 ? weekStartsOn - 1 : 0;
    }

    const currentWeekStart = startOfWeek(new Date(), {
      weekStartsOn: adjustedWeekStartsOn,
    }).toISOString();

    onChange(currentWeekStart);
  };

  const handleYearChange = (value) => {
    onChange(new Date(value));
  };

  useKeyPress(() => {
    handlePrevWeek();
  }, ['ArrowLeft']);

  useKeyPress(() => {
    handleNextWeek();
  }, ['ArrowRight']);

  const currentWeek = format(new Date(value), 'w', {
    weekStartsOn: weekStartsOn + 1,
  });
  const currentYear = format(new Date(value), 'yyyy-MM-dd', {
    weekStartsOn: weekStartsOn + 1,
  });

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={0}
      sx={{ flexWrap: 'wrap', gap: 1 }}
    >
      <Button onClick={handleSetCurrentWeek}>{t('button.current')}</Button>

      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
        <IconButton onClick={handlePrevWeek}>
          <Iconify icon={'ion:chevron-back'} />
        </IconButton>
        <Typography width={'30px'} align={'center'} variant="h6" color={'text.secondary'}>
          #{currentWeek}
        </Typography>
        <IconButton onClick={handleNextWeek}>
          <Iconify icon={'ion:chevron-forward'} />
        </IconButton>
      </Stack>

      <Box sx={{ width: '105px' }}>
        <RHFDatePicker value={currentYear} onChange={handleYearChange} name={'year'} label={'Year'} views={['year']} />
      </Box>
    </Stack>
  );
};

WeekPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  weekStartsOn: PropTypes.number,
  showPrevWeekLastDay: PropTypes.bool,
};

export default WeekPicker;
