import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const ConditionalTooltip = ({ title, children }) => {
  if (!title) {
    return children;
  }

  return (
    <Tooltip title={title}>
      <span>{children}</span>
    </Tooltip>
  );
};

ConditionalTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ConditionalTooltip;
